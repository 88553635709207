<template>
    <div class="col-12 col-lg-9 max-1085">
        <Upgrade />
        <div class="d-flex justify-content-between blockMob smallHead my-3 align-items-center">
            <h3 class="medium f-28 darkblueColor mb-0">{{$t('trading_accounts.trading_accountstitle')}} </h3>
            <a class="button fillBtn small pointer ps-1 d-flex align-items-center" :href="static_vars.domainURL+'select-a-broker'"  :class="{'disabled' : store.customerDetail?.readOnly}">
                <vue-feather class="rounded-circle plusIocn me-1" size="18" type="plus"></vue-feather>
                {{$t('trading_accounts.trading_accountscontent1')}}
            </a>
        </div>
        <div class="belowDropDown bg-white br-14 mt-2" v-if="store.userTradingAccountsList && store.userTradingAccountsList.length">
            <div class="position-relative p-3 repeatMain modalBottom" v-for="account,key in store.userTradingAccountsList" :key="key">
                <div class="d-flex align-items-center justify-content-between overflow-auto" v-if="!account.demo && account.depositFundsUrl">
                    <div class="d-flex align-items-center me-4 flex-shrink-0 customerDetail pointer" @click="switchAccount(account);accountInfo = account">
                        <div class="d-flex align-items-center me-3 rounded bordergS p-2" v-if="account.brokerLogo">
                            <v-lazy-image :src="static_vars.imagesURL+'/'+account.brokerLogo" :alt="$t('trading_accounts.trading_accountscontent13')" width="30" height=""/>
                        </div>
                        <div class="d-block">
                            <!-- <h6 class="medium mb-0 darkblueColor d-flex align-items-center line-2">{{account.name || ''}}</h6> -->
                            <p class="mb-0 gray f-13 d-flex align-items-center">{{account.providerStatus=='APPROVED' && account.providerName ? account.providerName : account.username || ''}} 
                                <span v-if="account.isLeader && account.providerStatus=='APPROVED'" class="status cardBorder d-flex align-items-center radius20 ms-2">
                                    <v-lazy-image src="/assets/images/win.svg" width="14" height="13" class="me-1" /> {{$t('trading_accounts.trading_accountscontent2')}}
                                </span>
                                <span :class="account.connectivityStatus == 'Connected' ? 'bg-secondary' : 'bg-danger'" class="py-1 px-2 f-11 text-uppercase ms-2 radius20 text-white medium line-1">{{account.connectivityStatus}}</span>
                                <span v-if="account.demo" class="bg-secondary py-1 px-2 f-11 text-uppercase ms-2 radius20 text-white medium line-1">{{$t('trading_accounts.trading_accountscontent3')}}</span>
                                <a  v-if="account.isLeader && account.providerStatus=='APPROVED' && (account?.importHistoryStatus == 'AVAILABLE' || account?.importHistoryStatus == 'FAILED')" @click.stop="showImportHistory(account)" class="medium small button rounded border-button zulu_btn">{{$t('trading_accounts.text15')}}</a>
                            </p>
                        </div> 
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center depositFundsUrl me-1"> 
                            <!-- v-if="account.isLeader" -->
                            <a v-if="account.depositFundsUrl" :href="account.depositFundsUrl" target="_blank" class="medium small button zulu_btn fillBtn rounded me-2"><vue-feather class="rounded-circle plusIocn me-2" size="18" type="plus"></vue-feather> {{$t('trading_accounts.trading_accountscontent14')}}</a>
                            <a v-if="account.withdrawFundsUrl" :href="account.withdrawFundsUrl" target="_blank" class="medium small button rounded border-button zulu_btn">{{$t('trading_accounts.trading_accountscontent4')}}</a>
                        </div>
                        <h6 class="mb-0 mx-3 f-18 neutralGrey regular">{{COMMON.getBaseCurrencySymbol()}}{{((account.balance || 0)+(account?.credit)).toFixed(2)}}<span class="f-10"></span></h6>
                        <a href="javascript:void(0);" v-if="!account.demo" @click="switchAccount(account);accountInfo = account">
                            <vue-feather v-if="isExpand==account.zuluAccountId" size="22" type="chevron-up"></vue-feather> 
                            <vue-feather v-else size="22" type="chevron-down"></vue-feather> 
                        </a>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between overflow-auto" :class="[{'pointer':!account.demo}]" v-else @click="switchAccount(account);accountInfo = account">
                    <div class="d-flex align-items-center me-4 flex-shrink-0 customerDetail" >
                        <div class="d-flex align-items-center me-3 rounded bordergS p-2" v-if="account.brokerLogo">
                            <v-lazy-image :src="static_vars.imagesURL+'/'+account.brokerLogo" :alt="$t('trading_accounts.trading_accountscontent13')" width="30" height=""/>
                        </div>
                        <div class="d-block">
                            <!-- <h6 class="medium mb-0 darkblueColor d-flex align-items-center line-2">{{account.name || ''}}</h6> -->
                            <p class="mb-0 gray f-13 d-flex align-items-center">{{account.providerStatus=='APPROVED' && account.providerName ? account.providerName : account.username || ''}} 
                                <span v-if="account.isLeader && account.providerStatus=='APPROVED'" class="status cardBorder d-flex align-items-center radius20 ms-2">
                                    <v-lazy-image src="/assets/images/win.svg" width="14" height="13" class="me-1" />{{$t('trading_accounts.trading_accountscontent2')}}
                                </span>
                                <span v-if="!account.demo" :class="account.connectivityStatus == 'Connected' ? 'bg-secondary' : 'bg-danger'" class="py-1 px-2 f-11 text-uppercase ms-2 radius20 text-white medium line-1">{{account.connectivityStatus}}</span>
                                <span v-if="account.demo" class="bg-secondary py-1 px-2 f-11 text-uppercase ms-2 radius20 text-white medium line-1">{{$t('trading_accounts.trading_accountscontent3')}}</span>
                               
                            </p>
                        </div> 
                    </div>
                    <div class="d-flex align-items-center">
                         <a  v-if="account.isLeader && account.providerStatus=='APPROVED' && (account?.importHistoryStatus == 'AVAILABLE' || account?.importHistoryStatus == 'FAILED')" class="medium small button rounded border-button zulu_btn px-3" @click.stop="showImportHistory(account)">{{$t('trading_accounts.text15')}}</a>
                        <div class="d-flex align-items-center depositFundsUrl me-1" v-if="account.isLeader">
                            <a v-if="account.depositFundsUrl" :href="account.depositFundsUrl" target="_blank" class="medium small button zulu_btn fillBtn rounded me-2"><vue-feather class="rounded-circle plusIocn me-2" size="18" type="plus"></vue-feather> {{$t('trading_accounts.trading_accountscontent14')}} </a>
                            <a v-if="account.withdrawFundsUrl" :href="account.withdrawFundsUrl" target="_blank" class="medium small button rounded border-button zulu_btn">{{$t('trading_accounts.trading_accountscontent15')}} </a>
                        </div>
                        <h6 class="mb-0 mx-3 f-18 neutralGrey regular">{{COMMON.getBaseCurrencySymbol()}}{{((account.balance || 0)+(account?.credit)).toFixed(2)}}<span class="f-10"></span></h6>
                        <a href="javascript:void(0);" v-if="!account.demo">
                            <vue-feather v-if="isExpand==account.zuluAccountId" size="22" type="chevron-up"></vue-feather> 
                            <vue-feather v-else size="22" type="chevron-down"></vue-feather> 
                        </a>
                    </div>
                </div>
                <div class="disclaimerPart py-1 f-13" v-if="account.demo">
                    {{$t('trading_accounts.text17')}}
                </div>
                <!-- Import History -->
                <!--  " -->
                <div class="activelight p-3 mt-4 br-8" v-if="showHistory==account.zuluAccountId && account.isLeader && account.providerStatus=='APPROVED' && (account?.importHistoryStatus == 'AVAILABLE' || account?.importHistoryStatus == 'FAILED')">
                    <div class="position-relative box-shadow-none show">
                        <div class="d-flex align-items-center">
                            <label class="cutomChechBox mb-0 me-3">
                                <p class="d-flex align-items-center mb-0">{{$t('trading_accounts.text14')}} 
                                    <!-- <i class="small fa fa-info-circle" aria-hidden="true"></i> -->
                                </p>
                                <input type="checkbox" class="form-control" name="leaderHsitory" v-model="leaderHistory" true-value="true" false-value="false" />
                                <span class="checkmark"></span>
                            </label>
                            <button v-if="historyLoading" class="button fillBtn zulu_btn py-3 px-md-5 f-16 medium">
                                <div class="spinner-border text-white smallSpinner me-1" role="status" >
                                    <span class="visually-hidden">{{$t('trading_accounts.trading_accountscontent6')}}</span>
                                </div> 
                            </button>
                            <button type="button" class="button fillBtn zulu_btn py-3 px-md-5 f-16 medium" :class="[{'disabled':!leaderHistory}]" @click="submitHistory(account)" v-else>{{$t('trading_accounts.text6')}}</button>
                        </div>
                        
                    </div>
                </div>
                <div class="activelight py-2 px-3 mt-4 br-8" v-if="showHistory==account.zuluAccountId && account.isLeader &&  account.providerStatus=='APPROVED' && (account?.importHistoryStatus == 'SUCCEEDED')">
                    <div class="viewDescription show" >
                        <p class="secondary f-14 mb-0 d-flex align-items-center"><vue-feather type="check-circle" size="15" class="me-2"></vue-feather> {{$t('trading_accounts.text16')}}</p>
                    </div>
                </div>
                <!-- Show Become a Leader -->
                <div class="dropdown_menu_animated position-relative box-shadow-none viewDescription pt-4 show" v-if="isExpand==account.zuluAccountId && !account.demo">
                    <Form @submit="updateSetting(account)" class="activelight p-3 br-8 mb-2">
                        <div class="changePassword mb-4">
                            <ul class="updateSetting position-relative d-flex flex-wrap mb-1">
                                <li>
                                    <float-label class="form-group focusLabel">
                                        <div class="position-relative d-flex align-items-center">
                                            <Field type="text" class="form-control disabled" v-model="platform" name="Platform" :placeholder="$t('trading_accounts.placeHolder1')" readonly/>
                                        </div>
                                    </float-label>
                                </li>
                                <li>
                                    <float-label class="form-group focusLabel">
                                        <div class="position-relative d-flex align-items-center">
                                            <Field type="text" class="form-control" v-model="serverIP" name="Server/IP" :placeholder="$t('trading_accounts.placeHolder2')" />
                                        </div>
                                    </float-label>
                                </li>
                                <li>
                                    <float-label class="form-group focusLabel">
                                        <div class="position-relative d-flex align-items-center">
                                            <Field type="text" class="form-control disabled" v-model="username" name="Username" :placeholder="$t('trading_accounts.placeHolder3')" readonly/>
                                        </div>
                                    </float-label>
                                </li>
                                <li>
                                    <float-label class="form-group mb-0">
                                        <div class="position-relative d-flex align-items-center eyeIcon focusLabel">
                                            <Field :type="showPass?'text':'password'" class="form-control" v-model="password" name="Password" :placeholder="$t('trading_accounts.placeHolder4')"  rules="required"/>
                                            <a href="javascript:void(0)" class="showHide" @click="showPass=!showPass"><vue-feather size="18" :type="showPass?'eye':'eye-off'"></vue-feather> </a>
                                        </div>
                                        <ErrorMessage class="text-danger d-block f-12 mt-1" name="Password" />
                                    </float-label>
                                </li>
                                <li class="submited d-flex justify-content-end mt-2">
                                    <button v-if="customSpinnerr" class="button fillBtn zulu_btn py-3 w-100 f-16 medium" >
                                        <div class="spinner-border text-white smallSpinner me-1" role="status" >
                                            <span class="visually-hidden">{{$t('trading_accounts.trading_accountscontent6')}}</span>
                                        </div>{{$t('trading_accounts.trading_accountscontent5')}} 
                                    </button>
                                    <button v-else type="submit" class="button fillBtn zulu_btn py-3 w-100 f-16 medium"  :class="{'disabled' : store.customerDetail?.readOnly}">{{$t('trading_accounts.trading_accountscontent7')}} </button>
                                </li>
                            </ul>
                        </div>
                    </Form>
                    <Form @submit="submit(account)">
                        <div class="activelight p-3 br-8">
                            <div class="flex-between">
                                <h6 class="medium mb-0">{{$t('trading_accounts.trading_accountscontent8')}} </h6>
                                <label class="switch" :class="[{'disabled':account.providerStatus=='APPROVED' },{'disabled':account.providerStatus=='PENDING' },{'disabled':account.providerStatus=='REJECTED' }]">
                                    <input type="checkbox" value="" id="leader" name="leader" :checked="isLeader" @change="makeAccountPublic($event)"/>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="mt-3" v-if="isLeader">
                                <!-- <div class="profilePicUpdate small p-0 mw-100 m-0">
                                    <div class="position-relative d-inline-flex justify-content-center mt-3">
                                        <h2>AK</h2>
                                        <Field type="file" name="File" class="file-upload-input"  id="fileUpload" ref="document" style="display: none;" />
                                        <a href="javascript:void(0)" class="position-absolute editable"><vue-feather type="upload" size="18"></vue-feather></a>
                                    </div>
                                    <div class="my-2">
                                        <p class="secondary py-2 alert f-14">Note: Changing the picture will override the account image for this Trading account</p>
                                    </div>
                                </div> -->
                                <div class="d-flex align-items-center mb-3">
                                    <div class="spotMessage">
                                        <div class="position-relative d-flex align-items-center">
                                            <float-label class="form-group mb-0 me-2">
                                                <div class="position-relative d-flex align-items-center">
                                                    <Field type="text" v-model="title" class="form-control largeInput me-2" :class="[{'disabled':account.providerStatus=='APPROVED'},{'disabled':account.providerStatus=='PENDING'}]" name="title" :placeholder="$t('trading_accounts.placeHolder5')" :rules="isTitleRequired" :readonly="account.providerStatus=='APPROVED' || account.providerStatus=='PENDING'"/>
                                                </div>
                                            </float-label>
                                            <span class="status medium bar border-0 f-14" :class="statusMessageClass" v-if="account.providerStatus">
                                                {{statusMessage}}
                                            </span>
                                        </div>
                                        <ErrorMessage class="text-danger d-block f-12 mt-1" name="title" />
                                    </div>
                                </div>
                                <div class="makeDes">
                                    <p class="f-14 mb-1 medium">{{$t('trading_accounts.trading_accountscontent9')}} </p>
                                    <div class="form-group">
                                        <!-- content grayBorder p-2 br-8 mb-2 -->
                                        <Field as="textarea" v-model="description" class="form-control largeInput w-100 textarea me-2 f-15" :class="[{'disabled':account.providerStatus=='PENDING'}]" name="description" placeholder="" :rules="isdescriptionRequired" :readonly="account.providerStatus=='PENDING'"/>
                                        <ErrorMessage class="text-danger d-block f-12 mt-1" name="description" />
                                    </div>
                                </div>
                                <div class="col-12 mb-4" v-if="!account.isLeader && account.providerStatus!='APPROVED' && (account?.importHistoryStatus == 'AVAILABLE')">
                                    <label class="cutomChechBox mb-0 me-3">
                                        <p class="d-flex align-items-center f-15">{{$t('trading_accounts.text14')}}</p>
                                        <input type="checkbox" class="form-control" name="importHistory" v-model="importHistory" true-value="true" false-value="false" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="dsve">
                                    <button v-if="customSpinner" class="button fillBtn zulu_btn py-3 px-md-5 f-16 medium">
                                        <div class="spinner-border text-white smallSpinner me-1" role="status" >
                                            <span class="visually-hidden">{{$t('trading_accounts.trading_accountscontent6')}}</span>
                                        </div> {{account.providerStatus ? 'Updating' : 'Saving'}}
                                    </button>
                                    <!-- <a v-else-if="account.demo && account.isLeader" @click="demoLeader=true" class="button fillBtn zulu_btn py-3 px-md-5 f-16 medium" href="javascript:void(0)">Save</a> -->
                                    <button v-else type="submit" class="button fillBtn zulu_btn py-3 px-md-5 f-16 medium" :class="[{'disabled':account.providerStatus=='PENDING'},{'disabled' : store.customerDetail?.readOnly}]">{{account.providerStatus ? 'Update' : 'Save'}}</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
		</div>
    </div>
    <div class="modal fade show" style="display: block;" v-if="demoLeader">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="demoLeader=false"></button>
                </div>
            <div class="modal-body text-center pt-0">
                <!-- <h4 class="secondary">Your Subscription Plan Changed Successfully</h4> -->
                <p class="mb-0">{{$t('trading_accounts.trading_accountscontent10')}}</p>
            </div>
            <div class="modal-footer border-0 justify-content-center">
                <button type="button" class="button secondary zulu_btn rounded border-button small me-1" @click="demoLeader=false">{{$t('trading_accounts.trading_accountscontent11')}} </button>
                <button type="button" class="button fillBtn zulu_btn small" @click="submit()">{{$t('trading_accounts.trading_accountscontent12')}} </button>
            </div>
            </div>
        </div>
    </div>
    <!-- Become a leader terms Popup -->
    <div class="modal fade newIntegratedBroker show" style="display: block;" v-if="Object.keys(becomeLeaderTermsObj).length">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <h6 class="modal-title semibold">{{$t('trading_accounts.text1')}}</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="becomeLeaderTermsObj={},isTermsAccepted=false"></button>
                </div>
            <div class="modal-body">
                <ul>
                    <li><strong>{{$t('trading_accounts.text2')}} {{getAccountWiseCopyLeaders.length}} {{$t('trading_accounts.text3')}}</strong></li>
                    <!-- <li v-if="Object.keys(subscriptionDetail || {}).length">
                        <strong>{{$t('trading_accounts.text7')}} <router-link class="underline" :to="{name : 'subscription'}" target="_blank">{{$t('trading_accounts.text13')}}</router-link> <span class="midgray f-14"><span class="secondary">${{parseFloat(subscriptionDetail.monthlyPaidAmount * subscriptionDetail.months).toFixed(2)}} / {{subscriptionDetail.months}} {{$t('trading_accounts.text9')}} / {{subscriptionDetail.totalSlotsSubscribed}} {{$t('trading_accounts.text8')}} </span></span> {{$t('trading_accounts.text4')}}</strong>
                    </li> -->
                    <p class="f-14 midgray">{{$t('trading_accounts.text10')}} {{Object.keys(subscriptionDetail || {}).length ? $t('trading_accounts.text11') : ''}} {{$t('trading_accounts.text12')}}</p>
                </ul>
            </div>
            <div class="modal-footer border-0 pt-0">
                <label class="cutomChechBox my-0 me-4"> {{$t('trading_accounts.text5')}}
                    <input type="checkbox" v-model="isTermsAccepted">
                    <span class="checkmark"></span>
                </label>
                <button type="button" class="button fillBtn zulu_btn m-0 px-md-5" :class="[{'disabled':!isTermsAccepted}]" @click="isTermsAccepted ? submit(becomeLeaderTermsObj) : ''">{{$t('trading_accounts.text6')}}</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
    import FloatLabel from 'vue-float-label/components/FloatLabel'
    import { myStore } from "@/store/pinia-store";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import Upgrade from './upgrade-header'
    import { useToast } from "vue-toastification";
    import moment from "moment";
    export default {
        setup() {
            const store = myStore();
            const toast = useToast();
            return { store, toast };
        },
        components: { Form, Field, ErrorMessage,Upgrade, FloatLabel },
        data() {
            return {
                isExpand: '',
                title: '',
                description: '',
                isLeader: false,
                customSpinner: false,
                customSpinnerr: false,
                demoLeader: false,
                accountInfo: {},
                platform: '',
                serverIP: '',
                username: '',
                password: '',
                showPass: false,
                statusMessage : '',
                statusMessageClass: '',
                becomeLeaderTermsObj: {},
                isTermsAccepted: false,
                subscriptionDetail: {},
                importHistory : false,
                showHistory : '',
                leaderHistory : false,
                historyLoading : false,

            };
        },
        computed: {
            getAccountWiseCopyLeaders() {
                if (Object.keys(this.store.copyTraders).length && this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                    let data = [];
                    if (this.store.allTypeAccount) {
                        if (this.store.allTypeAccount == "ALL_LIVE") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === false);
                        } else if (this.store.allTypeAccount == "ALL_DEMO") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === true);
                        }
                    } else {
                        if (Object.keys(this.store.userSelectedAccount).length) {
                            data = this.store.copyTraders.providers.filter((i) => i.zuluAccountId == this.store.userSelectedAccount.zuluAccountId);
                        } else {
                            data = [];
                        }
                    }
                    return data;
                } else {
                    return [];
                }
            },
        },
		methods:{
            expireDate(date){
                return moment(date).format('MMM. DD, YYYY')
            },
            showImportHistory(acc){
                this.isExpand = ''
                if(this.showHistory != acc?.zuluAccountId){
                    this.showHistory = acc?.zuluAccountId
                }else{
                    this.showHistory = ''
                }
            },
            switchAccount(acc){
                this.showHistory = ''
                this.title=''
                this.description=''
                this.platform= ''
                this.serverIP=''
                this.username=''
                this.password=''
                this.statusMessage=''
                this.statusMessageClass=''
                this.isLeader = false
                if(this.isExpand==acc.zuluAccountId){
                    this.isExpand = ''
                } else {
                    this.isExpand=acc.zuluAccountId
                    if(acc.providerStatus == 'REJECTED'){
                        this.statusMessage = 'Your Strategy has been rejected.  You can change your strategy description and try again.'
                        this.statusMessageClass = 'redBtn'
                    } else if(acc.providerStatus == 'PENDING') {
                        this.statusMessageClass = 'yellowBtn'
                        this.statusMessage = 'Your Strategy is Pending Approval. Please wait...'
                    } else if(acc.providerStatus=='APPROVED'){
                        this.statusMessage = 'Strategy Approved'
                        this.statusMessageClass = 'greenBtn'
                    }
                    if(acc.demo != true){
                        this.store.getBrokerAccountDetail({},false,acc.brokerAccountId).then((res)=>{
                            if(res){
                                if(Object.keys(this.store.brokerAccountDetail).length){
                                    this.platform = this.store.brokerAccountDetail.brokerPlatformName
                                    this.serverIP = this.store.brokerAccountDetail.serverAddress
                                    this.username = this.store.brokerAccountDetail.username
                                }
                            }
                        })
                    }
                    if(acc.providerStatus){
                        this.isLeader = acc.isLeader
                        this.store.getProviderTitleDesc({},true,acc.providerId,this).then(res=>{
                            if(res){
                                if(Object.keys(this.store.tradingAccountTitleDesc).length){
                                    this.title = this.store.tradingAccountTitleDesc.name || ''
                                    this.description = this.store.tradingAccountTitleDesc.strategyDesc ||''
                                }
                            }
                        })
                    }
                }
            },
            makeAccountPublic(e){
                this.isLeader = e.target.checked
            },
            isTitleRequired(value) {
                if (value && value.trim()) {
                    if(value.length < 8 || value.length > 30){
                        return 'Title length should be between 8 and 30 characters'
                    } else {
                        return true;
                    }
                } else {
                    return 'This Field is required';
                }
            },
            isdescriptionRequired(value){
                if (value && value.trim()) {
                    if(value.length < 512 || value.length > 2000){
                        return 'Description length should be between 512 and 2000 characters'
                    } else {
                        return true;
                    }
                } else {
                    return 'This Field is required';
                }
            },
            submitHistory(acc){
                this.store.LeaderAccImportHistory({},true,acc?.providerId,this).then((res) => {
                    if(res===true){
                        this.store.callCustomerDetail({},false);
                    }
                })
            },
            submit(acc){
                if(acc.providerStatus && (acc.providerStatus=='APPROVED' || acc.providerStatus=='REJECTED')){
                    let payload = {
                        "strategyDescription": this.description,
                        "targetZuluAccountId": acc.zuluAccountId
                    }
                    if(acc.providerStatus=='REJECTED'){
                        payload["strategyName"] = this.title
                        this.store.updateBrokerDesc(payload,true,this,'rejected')
                    } else if(acc.providerStatus=='APPROVED'){
                        this.store.updateBrokerDesc(payload,true,this,'approved')   
                    }
                    this.statusMessage = 'Your Strategy is under review'
                    this.statusMessageClass = 'yellowBtn'
                } else {
                    this.becomeLeaderTermsObj = acc
                    if(this.isTermsAccepted){
                        let payload = {
                            "strategyName": this.title,
                            "strategyDescription": this.description,
                            "targetZuluAccountId": acc.zuluAccountId
                        }
                        this.store.accountsBecomeLeader(payload,true,this).then(()=>{
                            this.statusMessageClass = 'yellowBtn'
                            this.statusMessage = 'Your Strategy is Pending Approval. Please wait...'
                            this.store.callCustomerDetail({},false)
                            this.store.GetCopyTraders({}, false);
                            this.becomeLeaderTermsObj = {}
                            this.isTermsAccepted = false
                        })
                    }
                }
            },
            // termsAcceptedToast(){
            //     this.toast.error("Please accept T&C to Become A Leader");
            // },
            updateSetting(acc){
                let payload = {
                    "password": this.password, 
                    "serverName": this.serverIP
                }
                this.store.postBrokerAccountDetail(payload,true,acc.brokerAccountId,this).then(res=>{
                    if(res){ setTimeout(() => { this.updateAccountDetail() }, 1000); }
                })
            },
            // planShowsList(){
            //     this.store.subscriptionSelectPlanUpdate({},false).then(res=>{
            //         if(res){ this.subscriptionDetail = res.find(p=> (p.status == 'ACTIVE' && p.type == 'MAIN')) }
            //     })
            // },
            updateAccountDetail(){
                this.store.callCustomerDetail({}, false)
            }
		},
		created(){ this.updateAccountDetail() }
    };
</script>   